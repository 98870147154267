import Modal from "react-modal"
import './ModalConfirm.scss'
import React from "react";
import Button from "../elements/Button/Button";
import {useIntl} from "react-intl";

export default function ModalConfirm({modalIsOpen,title, onRequestClose, onConfirm, loading, message}) {

    const intl = useIntl();

    return (<Modal
        closeTimeoutMS={200}
        isOpen={modalIsOpen}
        className={"sign-contract"}
    >
        <div className={"head"}>
            <div className={"header pt-0 d-flex"} style={{width: '-webkit-fill-available'}}>
            <div className={"title"}>{title}</div>
                <div className={"close"} onClick={() => onRequestClose()}>
                    <span><i className="fa-solid fa-xmark"></i></span>
                </div>
            </div>
        </div>

        <div className={"content"}>
            <div className={"row"}>
                <div className={"col-12"}>
                    <div className={"form-control"} style={{paddingBottom: '200px'}}>
                        <div
                            className={"alert alert-danger"}>{message}</div>
                    </div>
                </div>
            </div>
        </div>
        <div className={"footer"}>

            <div className={"row"}>
                <div className={"col-md-6 col-sm-12 d-flex"}>
                    <Button
                        className={"btn-light mt-4"}
                        value={intl.formatMessage({id: 'general.buttons.close'})}
                        type={'button'}
                        onClick={()=> onRequestClose()}
                    />
                </div>
                <div className={"col-md-6 col-sm-12 d-flex"}>
                    <Button
                        className={"btn-error mt-4 ms-auto"}
                        value={intl.formatMessage({id: 'general.yes'})}
                        type={'button'}
                        disabled={loading}
                        loading={loading}
                        onClick={()=> onConfirm()}
                    />
                </div>
            </div>

        </div>

    </Modal>)
}
