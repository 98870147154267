import {NavLink} from "react-router-dom";
import React from "react";
import {useIntl} from "react-intl";

export default function ContainerSmall(
    {
        link,
        value,
        showCurrency,
        img,
        translate
    }
) {

    const intl = useIntl()
    const currency = localStorage.getItem('currency')


    return (

        <NavLink className="card" to={link}>
            <div className={"container-small"}>
                <div className={"d-flex"}>
                    <img src={"/images/dashboard/" + img} className={"location-icon medium"} alt={""}/>
                    <div className={"right-container ms-auto"}>
                        {intl.formatMessage({id: 'general.' + translate})}
                        <div className={"item-container ms-auto"}>{parseFloat(value).toLocaleString('ro-RO')} {showCurrency ? 'Lei' : ''}</div>
                    </div>
                </div>
            </div>
        </NavLink>
    );
}

